import React, { useState } from "react";
import useForm from "react-hook-form";
import "../../assets/css/login.css";
import "../../assets/css/vanillatoasts.css";
import * as common from "../../utils/common";
import * as API from "../../utils/api";
import { Model, addChangeListener } from "../../data/Model";

Model.branch_list = Model.branch_list || [];
API.add_request_item('getShopBranchList', {}, function (r) {
  console.log('getShopBranchList r:', r); 
    if(r && r.success && r.payload) {
        Model.branch_list = r.payload;
    }
});

const Login = ({ history }) => {

  const [branchList, setBranchList] = useState(Model.branch_list);
  addChangeListener('branch_list', 'Login/index.js', function(prop, oldv, newv) {
    setBranchList(newv);
  });
  

  // check login 
  if(Model.token) { history.push("/main"); }

  const { register, handleSubmit } = useForm();

  const onSubmit = data => {
    if(!data.branch_code) {
      common.showMessage('warning', '매장을 선택해주세요.'); return false;
    }
    API.add_request_item('loginAdmin', {'userid':data.userid, 'userpw':data.user_password, 'branch_code':data.branch_code}, function(r){
      if(r && r.success && r.payload.token) {
        Model.token = r.payload.token;
        Model.branch_code = data.branch_code;
        Model.user_id = data.userid;
        history.push("/main");
        setTimeout(function(){window.location.reload();}, 200);
      } else {
        Model.token = '';
        Model.branch_code = 0;
        common.showMessage('error', '계정정보를 확인해주세요.', '로그인 실패'); return false;
      }
    });
  };

  return (
    <div className="login_wrap">
      <div className="login_box">
        <h1 className="logo">WKON*AISUL Coffee</h1>
        {/* <p className="welcome">Welcome to Admin</p> */}
        {/* <p className="inc">WKON*AISUL COFFEE, Inc.</p> */}
        <p className="dec">Login in. To see it in action.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectBranch items = { branchList } register = {register} /> 
          <input type="text" name="userid" placeholder="User id" ref={register} value="wkon1f"/>
          <input
            type="password"
            name="user_password"
            placeholder="Password"
            ref={register}
            value="aisul"
          />
          <button type="submit" className="login_btn">
            Login
          </button>
        </form>
        <p className="copyright">WKON*AISUL COFFEE, Inc. © 2023</p>
      </div>
    </div>
  );
};

const SelectBranch = ({ items, register }) => {
  // 첫번째 항목 선택하기.
  // for (let i = 0;  i<items.length; i++) {
  //   items[i].selected = i === 0 ? true : false;
  // }
  // selected={item.selected}
  return ( 
      <select name="branch_code" ref={register}>
          {/* <option value="">매장을 선택해주세요.</option> */}
          {items.map((item, index) => ( 
          <option value={item.branch_code}  >{item.branch_name}</option>
          ))} 
      </select>
  );
};
export default Login;
