import axios from 'axios';
import { Model } from "../data/Model";

const API_URL = "https://api.siaplay.kr";

var items = [],
    callbacks = [],
    timeoutkeys = [],
    // runmode = window.location.host ? 'web' : process.versions['electron'] ? 'pc' : 'mobile',
    // key_request = '',
    host = window.location.host ? '//' + (window.location.host.indexOf('localhost')>=0 ? 'api.loc.siaplay.kr' : 'api.'+window.location.host) + '/v1.0' : API_URL,
    host = host.replace(/\/api.(work|shop).siaplay.kr\/v1.0/, '/api.siaplay.kr/v1.0')
    ;

function request_api() {
    setTimeout(request_api, 300); //key_request = 
    if (items && items.length > 0) {
        var _items = items, _callbacks = callbacks;
        items = []; callbacks = [];
        let form = new FormData();
        form.append('item', JSON.stringify(_items));
        if(Model.token) {form.append('token', Model.token);}
        axios
        .post(host + '/request/?', form)
            .then(function (r) {
            for (var i in r.data.payload) {
                const tr = r.data.payload[i];
                const response = JSON.parse(tr.data);
                if (_callbacks[i]) { _callbacks[i](response, i); }
            }
        })
        .catch(function(error) {
            console.error('request_api error: ', error);
        });
    }
};
request_api();

export const add_request_item = (method_name, params, callback, repeat_time) => {
    var item = {"method": method_name,"params": params};
	if(Model.token) {item.params.token = Model.token;}
    for ( var i in items) {
		console.log('===================');
		console.log('item[i]:',JSON.stringify(items[i]));
		console.log('item:',JSON.stringify(item));
        if( JSON.stringify(items[i]) == JSON.stringify(item) ) {
            console.log('중복시 추가 종료.');
            return ; // 중복시 추가 종료.
        }
    }
    items.push(item);
    let indexno = items.length-1;
    callbacks[indexno] = callback;
    if(repeat_time>0) {
        let newtimeoutkey = setTimeout(function(){
            add_request_item (method_name, params, callback, repeat_time);
        }, repeat_time);
        timeoutkeys[indexno] = newtimeoutkey;
    }
};

export const stop_request = (i) => {
    clearTimeout(timeoutkeys[i]);
}

export const is_login = () => {
    return Model.token ? true : false;
}

const check_duplicatd_request = (item) => {
  for ( var i in items) {

  }
}