import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "../pages/Login";
import Main from "../pages/Main";
import Orders from "../pages/Orders";

export default () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/main" component={Main} />
      <Route exact path="/orders" component={Orders} />
    </Switch>
  </Router>
);
