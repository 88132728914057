import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import "../../assets/css/orders.css";

import { Model, addChangeListener, convertOrderData } from "../../data/Model";
import * as API from "../../utils/api";
// import { addCallback } from "../../utils/firebase";
import ReactPaginate from 'react-paginate';

console.log('================================ Orders/index.js 시작 ==============================');

// order 리스트 목록 데이터
let items = Model.history || [];
let first_load = false;
Model.order_list_page = Model.order_list_page || 0;

const Orders = ({ history }) => {
  console.log('================================ Orders Class 시작 ==============================');

// check login 
if (!Model.token) { history.push("/"); }

  const max_rows = Math.floor((window.innerHeight - 200 )/33) || 20;
  const [closeModal, setCloseModal] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [cntPage, setCntPage] = useState(max_rows);
  const [totalPage, setTotalPage] = useState(1); // = Math.ceil(data.meta.total_count / data.meta.limit),
  const [totalRows, setTotalRows] = useState(1); // Math.ceil(data.meta.total_count / data.meta.limit),

  addChangeListener('history', 'Orders/index.js', function(prop, oldv, newv) {
    // console.log('history callback start');
    // console.log('newv:', newv);
    // console.log('Orders/index.js items:', items);
    items = convertOrderData(newv);
    // items = newv;
    // console.log('items:', items);
    setOrderList(items);
  });

  const branch_code = Model.branch_code || 0;
  /**
   * 서버에서 주문정보를 가져오는 함수.
   * @param {Number} interval 반복용청할 시간. milli seconds
   */
  const getTotalOrderData = (interval) => {
    // console.log('getTotalOrderData start ');
    API.add_request_item('getShopOrderList', { 'branch_code': branch_code, 'status': 'history', 'page':Model.order_list_page+1, 'rows':cntPage }, function(r) {
    //   console.log('Orders/index.js add_request_item history callback response', r);
      if (r && r.success && r.payload) {
        // console.log('payload: ', r.payload);
        Model.history = r.payload;
        let total_rows = r.payload[0].total_rows ? r.payload[0].total_rows : 0;
		// console.log('total_rows: ', total_rows);
		setTotalPage( total_rows ? Math.ceil(total_rows / cntPage) : 1 );
        
      }
      if(r && r.error && r.error.code==='001') { // 로그아웃상태일때 로그인페이지로이동
        Model.token = '';
        // history.push("/");
      }
    }, interval);
  };
  if (branch_code > 0 && !first_load) {
	first_load = true;
    getTotalOrderData(); // 반복요청하면 안됨. const Orders가 페이지 이동할때마다 호출하기 때문에 중복해서 서버에 요청됨. 페이지 이동을 해도 마찬가지... 그래서 단건으로 처리해야 함.
    // addCallback('reload_history', getTotalOrderData );// push 반응함수 등록
  }

  const togglecloseModal = () => {
    setCloseModal(!closeModal);
  };

  const closeApp = () => {
    togglecloseModal();
    alert("앱종료");
  };

//   const pageChange = i => {
//     // 페이지 이동시 데이터를 다시 불러와 주세요
//     // console.log(`${i} 번 페이지 이동`);
//     setOrderList(items);
//   };

  useEffect(() => {
    setOrderList(items);
  }, []);

  const stateClassName = state => {
    let name = "";
    switch (state) {
      case "결제":
        name = "state_1";
        break;
      case "준비중":
        name = "state_2";
        break;
      case "완료":
        name = "state_3";
        break;
      case "거래완료":
        name = "state_4";
        break;

      default:
        break;
    }
    return name;
  };

  const handlePageClick = data => {
	Model.order_list_page = data.selected;
	getTotalOrderData();
  };

  return (
    <div className="orders_wrap">
      <Header history={history} />
      <div className="order_bar">
        <h2 className="order_title">주문 내역</h2>
        <div className="user">{Model.user_id}</div>
      </div>

      <div className="order_contents">
        <table className="order_table">
		  <colgroup>
			<col width="90px" />
			<col width="110px" />
			<col width="80px" />
			<col width="*" />
			<col width="180px" />
			<col width="120px" />
		  </colgroup>
          <thead>
            <tr>
              <th>No</th>
              <th>주문자 ID</th>
              <th>상태</th>
              <th>주문내용</th>
              <th>주문합계/결제금액/보너스</th>
              <th>일시</th>
            </tr>
          </thead>
		  </table>
		  <div className="order_table_body">
		  <table className="order_table">
		  <colgroup>
			<col width="90px" />
			<col width="110px" />
			<col width="80px" />
			<col width="*" />
			<col width="180px" />
			<col width="120px" />
		  </colgroup>
          <tbody>
            {orderList.map(order => (
              <tr key={order.no}>
                <td>{order.no}</td>
                <td>{order.user_id}</td>
                <td className={`state ${stateClassName(order.state)}`}>
                  {order.state}
                </td>
                <td className="contents">
                  {order.orders.map(o => `${o.name} ${o.amount}개, `)}
                </td>
                <td className="total">{order.total}</td>
                <td>{order.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
		</div>
      </div>

      <div className="pagination_wrap">
        <ReactPaginate
		  initialPage={Model.order_list_page}
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={totalPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          pageClassName={'page-item'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          breakClassName={'page-item'}
          activeClassName={'active'}
        />
      </div>

      {/* <button className="close_btn" onClick={togglecloseModal}>
        GAZA Coffee 종료하기
      </button> */}
      <Modal
        className="close_modal"
        active={closeModal}
        cancel={togglecloseModal}
        confirm={closeApp}
        close={togglecloseModal}
      >
        <div className="alert">GAZA Coffee를 종료하시겠습니까?</div>
      </Modal>

    </div>
  )

}

export default Orders;
