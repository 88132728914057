import React, { useState, useEffect } from "react";
import moment from "moment";
import "../assets/css/header.css";

import { Model } from "../data/Model";

const Header = ({ history }) => {
  const [newOrder, setNewOrder] = useState(false); // 새 주문이 있는 경우

  useEffect(() => {}, []);

  const admin = () => {
    alert("메뉴 등록 등 관리자");
  };
  const logout = () => {
    Model.token = '';
    Model.branch_code = '';
    history.push("/");
  }

  const moveOrder = () => {
    history.push("/orders");
  };

  const moveIndex = () => {
    history.push("/main");
  };

  return (
    <div className="header_coffee">
      <button
        className={`btn_order ${newOrder ? "new" : ""}`}
        onClick={moveOrder}
      >
        My GAZA Coffee
      </button>
      <h1 className="logo" onClick={moveIndex}>
        GAZA COFFEE
      </h1>
      <div className="right_menu">
        {moment().format("YYYY-MM-DD")}
        <button onClick={logout} className="btn_admin">
            로그아웃
        </button>
      </div>
    </div>
  );
};

export default Header;
