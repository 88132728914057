import React, { useState, useEffect } from "react";
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import { Model, addChangeListener, convertOrderData } from "../../data/Model";
import * as API from "../../utils/api";
import {showMessage} from "../../utils/common";
// import { addCallback } from "../../utils/firebase";
import "../../assets/css/main.css";
// import bg_new_order from "../../assets/sound/new_order.mp3";

console.log('================================ Main/index.js 시작 ==============================');


const play_sound = () => {
    var audio = new Audio();
    audio.src = "/new_order.mp3";
    audio.play();
}

let api_added = 0;

/**
 * 서버에서 주문정보를 가져오는 함수.
 * @param {Number} interval 반복용청할 시간. milli seconds
 */
const getOrderData = (interval) => {
    if(api_added>0) { return ;}
    API.add_request_item('getShopOrderList', { 'branch_code': branch_code, 'status': 'workboard', 'rows':'10000' }, function(r, i) {
        // console.log('Main/index.js add_request_item workboard callback response', r);
        if (r && r.success && r.payload) {
            Model.orders = r.payload;
            for( let i=0; i<r.payload.length; i++) {
                // console.log(Model.max_order,r.payload[i].order_code, Model.max_order < r.payload[i].order_code);
                if( Model.max_order < r.payload[i].order_code ) {
                    Model.max_order = r.payload[i].order_code
                    showMessage('success', '신규 주문이 있습니다. 주문번호:'+(r.payload[i].order_code), '신규주문발생', 5*60*1000, function(){
                        // const card = document.getElementById(r.payload[i].order_code);
                        // if(card){card.scrollIntoView();}
                    });
                    play_sound();
                }
            }
        }
        if (r && r.error && r.error.code === '001') { // 로그아웃상태일때 로그인페이지로이동
            API.stop_request(i);
            Model.token = '';
            Model.branch_code = '';
        }
    }, interval);
    api_added++
};
const branch_code = Model.branch_code || 0;

const changeOrderStatus = (order_code, status, callback) => {
    let status_code = '';
    switch (status) {
        case '취소':
            status_code = 'ax';
            break;
        case '접수':
            status_code = 'ac';
            break;
        case '완료':
            status_code = 'ad';
            break;
        case '거래 완료':
            status_code = 'ae';
            break;
        case '준비중':
            status_code = 'ab';
            break;
    }
    API.add_request_item('changeShopOrderStatus', { 'order_code': order_code, 'status_code': status_code }, function(r) {
        if (r && r.success) {
            if (!r.payload) {
                getOrderData();
                window.showMessage('error', '상태를 변경하지 못했습니다.', '오류', 60 * 60 * 24 * 365);
                // setTimeout(function(){window.location.reload();}, 800);
            } else {
							if(callback && typeof callback === typeof function(){}) {
								callback();
							}
						}
        }
        if (r && r.error) { 
            if(r.error.code === '001') {// 로그아웃상태일때 로그인페이지로이동
                Model.token = '';
            } else {
                window.showMessage('error', r.error.error_msg ? r.error.error_msg : '상태를 변경하지 못했습니다.', '오류', 60 * 60 * 24 * 365);
                // setTimeout(function(){window.location.reload();}, 800);
            }
        }
    });
}

const cancelOrderStatus = (order_code, callback) => {
	API.add_request_item('cancelShopOrder', { 'branch_code': branch_code, 'order_code': order_code }, function(r, i) {
		if (r && r.success && r.payload) {
			if(callback && typeof callback === typeof function(){}) {
				callback();
			}
		}
		if (r && r.error && r.error.code === '001') { // 로그아웃상태일때 로그인페이지로이동
				API.stop_request(i);
				Model.token = '';
				Model.branch_code = '';
		}
	});
}

// 데이터로 받아오기
const coinName = "GWS";
const newItem = {
    no: "126",
    user_id: "ARA-123456",
    state: "준비중",
    total: "￦7,000 / 0.005 GWS / 70P",
    date: "13:16(19-11-07)",
    sum: "7,000",
    sumCoin: "0.005",
    orders: [
        { name: "아메리카노(hot)", amount: 2 },
        { name: "아메리카노(hot)", amount: 2 },
        { name: "아메리카노(hot)", amount: 2 }
    ]
};

const Main = ({ history }) => {

    console.log('================================ Main Class 시작 ==============================');

    // check login 
    if (!Model.token) { history.push("/"); }

    if (branch_code > 0) {
        Model.max_order = Model.max_order || "";
        getOrderData(5000); // 15마다 데이터 반복 요청 등록
        // addCallback('reload_order', getOrderData); // push 반응함수 등록
    }
    
		let orders = convertOrderData(Model.orders) || [];

    addChangeListener('orders', 'Main/index.js', function(prop, oldv, newv) {
        // console.log('callback start');
        // console.log('new orders:', newv);
        orders = convertOrderData(newv);
        const items = getList();
        
        setStandBy(items.standBy.reverse());
        setReceipt(items.receipt);
    });

    const [standBy, setStandBy] = useState([]);
    const [receipt, setReceipt] = useState([]);
    const [prepareModal, setPrepareModal] = useState(false); // 새 주문이 있는 경우 true 변경
    const [removeModal, setRemoveModal] = useState(false);
    const [removeId, setRemoveId] = useState();
    const [removeItem, setRemoveItme] = useState({});
    const [cancelModal, setCancelModal] = useState(false);
    const [cancelId, setCancelId] = useState();
    const [cancelItem, setCancelItme] = useState({});

    const togglePrepareModal = () => {
        setPrepareModal(!prepareModal);
    };

    const addOrder = order => {
        setPrepareModal(!prepareModal);
        order.state = "준비중";
        order.state_next = "접수";
        orders.push(order);
        const items = getList();
        setStandBy(items.standBy.reverse());
        setReceipt(items.receipt);
    };

    const toggleRemoveModal = () => {
        setRemoveModal(!removeModal);
    };

    const onRemoveOrder = _ => {
        // console.log('onRemoveOrder start');
        orders.forEach((i, idx) => {
            if (i.order_code === removeId) {
                changeOrderStatus(i.order_code, '거래 완료');
                i.state = "거래 완료";
                orders.splice(idx, 1);
            }
        });
        const items = getList();
        setStandBy(items.standBy.reverse());
        setReceipt(items.receipt);
        toggleRemoveModal();
    };

    const toggleCancelModal = () => {
			setCancelModal(!cancelModal);
		};

		const onCancelOrder = _ => {
				console.log('onCancelOrder start');
				orders.forEach((i, idx) => {
						if (i.order_code === cancelId) {
							// cancelOrderStatus(cancelId, function(){
							// 	i.state = "취소";
							// 	orders.splice(idx, 1);
							// 	const items = getList();
							// 	setStandBy(items.standBy.reverse());
							// 	setReceipt(items.receipt);
							// 	toggleCancelModal();
							// });

							changeOrderStatus(i.order_code, '취소', function(){
								i.state = "취소";
								orders.splice(idx, 1);
								const items = getList();
								setStandBy(items.standBy.reverse());
								setReceipt(items.receipt);
								toggleCancelModal();
							});
						}
				});
		};

    const getList = () => {
        const list = {
            standBy: [],
            receipt: []
        };
        // console.log('orders:', orders);
        orders.forEach(i => {
            if (i.state == "준비중") {
                list.standBy.push(i);
            } else if (i.state == "접수" || i.state == "완료") {
                list.receipt.push(i);
            }
        });
        // console.log('list:', list);
        return list;
    };

    const stateUpdate = (id, state) => {
        orders.forEach(i => {
            if (i.no === id) {
                // console.log(state);
                switch (state) {
                    case "준비중":
                        changeOrderStatus(i.order_code, '접수');
                        i.state = "접수";
                        i.state_next = "완료";
                        break;
                    case "접수":
                        changeOrderStatus(i.order_code, '완료');
                        i.state = "완료";
                        i.state_next = "거래 완료";
                        break;
                    case "완료":;
                        setRemoveItme(i);
                        setRemoveId(i.order_code);
                        toggleRemoveModal();
                        break;
                    default:
                        break;
                }
            }
        });
        const items = getList();
        setStandBy(items.standBy.reverse());
        setReceipt(items.receipt);
    };

    const cancelOrder = (id, state) => {
			orders.forEach(i => {
					if (i.no === id) {
						setCancelItme(i);
						setCancelId(i.order_code);
						toggleCancelModal();
					}
			});
    }

    useEffect(() => {
        const items = getList();
        setStandBy(items.standBy.reverse());
        setReceipt(items.receipt);
    }, []);

    return ( <div className = "main_wrap" >
        <Header history = { history }/> 
            <div className = "order_contents" >
                <div className = "order_box stand_by" >
                    <h2 className = "order_state_title" > 대기중({ standBy.length }) </h2> 
                    <OrderBox items = { standBy } stateUpdate = { stateUpdate } cancelOrder = { cancelOrder } /> 
                </div> 
                <div className = "order_box receipt" >
                    <h2 className = "order_state_title" > 작업중({ receipt.length }) </h2> 
                    <OrderBox items = { receipt } stateUpdate = { stateUpdate } cancelOrder = { cancelOrder }/> 
                </div> 
            </div> 
            <PrepareModal active = { prepareModal } addOrder = { addOrder } close = { togglePrepareModal } /> 
            <Modal className = "remove_modal" active = { removeModal } cancel = { toggleRemoveModal } confirm = { onRemoveOrder } close = { toggleRemoveModal } >
            <div className = "order_top" >
                <p className = "tit" > 주문 No: { removeItem.no } < br / > 주문 ID: { removeItem.user_id } </p> 
                <p className = "state" > 상 태: < strong > { removeItem.state } </strong></p >
                <p className = "date" > { removeItem.date } </p> 
            </div> 
						<ul className = "orders" > 
						{removeItem.orders && removeItem.orders.map((i, idx) => ( 
								<li key = { `order_${idx}` } ><span className = "name" > { i.name } </span> <span className = "amount" > { i.amount }개 </span> </li>
						))} 
						</ul> 
            <div className = "alert" > 이 주문 내역을 삭제하시겠습니까 ? </div> 
            </Modal>
            <Modal className = "cancel_modal" active = { cancelModal } cancel = { toggleCancelModal } confirm = { onCancelOrder } close = { toggleCancelModal } >
            <div className = "order_top" >
                <p className = "tit" > 주문 No: { cancelItem.no } < br / > 주문 ID: { cancelItem.user_id } </p> 
                <p className = "state" > 상 태: < strong > { cancelItem.state } </strong></p > 
                <p className = "date" > { cancelItem.date } </p> 
            </div> 
						<ul className = "orders" > 
						{cancelItem.orders && cancelItem.orders.map((i, idx) => ( 
								<li key = { `order_${idx}` } ><span className = "name" > { i.name } </span> <span className = "amount" > { i.amount }개 </span> </li>
						))} 
						</ul> 
            <div className = "alert" > 이 주문 내역을 취소하시겠습니까 ? </div> 
            </Modal>
        </div>
    );
};

const OrderBox = ({ items, stateUpdate, cancelOrder }) => {
    return ( 
        <ul className = "order_list" > 
            {items.map((item, index) => ( 
            <li id={ item.no }  className = "order_item" key = { item.no } >
                <div className = "order_tit" >
                    <div className = "no" ><span className = "num" > { index < 9 ? `0${index + 1}` : index + 1 } </span> NO. { item.no } </div> 
                    <dl className = "dec" ><dt > 주문 시간 </dt> <dd > { item.date } </dd> <dt > 주문 ID </dt> <dd > { item.user_id } </dd> </dl> 
                </div> 
                <ul className = "orders" > 
                {item.orders.map((i, idx) => ( 
                    <li key = { `order_${idx}` } ><span className = "name" > { i.name } </span> <span className = "amount" > { i.amount }개 </span> </li>
                ))} 
                </ul> 
                <button type = "button" onClick = { () => { cancelOrder(item.no, item.state); } } className = { `cancel_btn ${ item.state === "준비중" ? "blue" : "gray" }` } > 취소 </button> 
                <button type = "button" onClick = { () => { stateUpdate(item.no, item.state); } } className = { `state_btn ${ item.state === "거래 완료" ? "done" : "active" }` } > { item.state_next } </button> 
            </li>
            ))} 
        </ul>
    );
};

const PrepareModal = ({ active, addOrder, close }) => {
    const onAddOrder = () => {
        addOrder(newItem);
    };

    return ( 
        <Modal className = "prepare_modal" active = { active } confirm = { onAddOrder } close = { close } >
            <div>
                <div className = "order_top" >
                    <p className = "tit" > 주문 No: { newItem.no } < br / > 주문 ID: { newItem.user_id } </p> <p className = "state" > 상 태: < strong > { newItem.state } </strong></p >
                    <p className = "date" > { newItem.date } </p> 
                </div> 
                <ul className = "orders" > 
                    {newItem.orders.map((order, idx) => ( 
                    <li key = { `order_${idx}` } ><span className = "name" > { order.name } </span> <span className = "amount" > { order.amount }개 </span> </li>
                    ))} 
                    <li className = "total" ><span className = "name" > 주문합계 </span> <span className = "amount" > ￦{ newItem.sum } </span> </li> 
                </ul> 
                <div className = "orders price" >
                    <span className = "name" > 결제금액 </span> <span className = "amount" > { newItem.sumCoin } { coinName } </span> 
                </div> 
            </div> 
        </Modal>
    );
};

export default Main;