// 숫자에 ,를 찍는
export const nComma = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getURLParameter = (sParam) => {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1] ? sParameterName[1] : '';
        }
    }
    return '';
}

var VanillaToasts = require('vanillatoasts');

export const showMessage = (type, text, title, timeout, callback) => {
    VanillaToasts.create({
        'title': title || null,
        'text': text || '',
        'type': type || 'info', // success, info, warning, error   / optional parameter
        // 'icon': '../assets/images/common/my.png', // optional parameter
        'timeout': timeout || 5000, // hide after 5000ms, // optional parameter
        'callback': callback || function(){} // executed when toast is clicked / optional parameter
    });
}
window.showMessage = showMessage; // global 선언.

// object를 키 이름으로 정렬하여 반환
export const sortObject = (o) => {
    var sorted = {},
    key, a = [];
    // 키이름을 추출하여 배열에 집어넣음
    for (key in o) {
        if (o.hasOwnProperty(key)) a.push(key);
    }
    // 키이름 배열을 정렬
    a.sort();
    // 정렬된 키이름 배열을 이용하여 object 재구성
    for (key=0; key<a.length; key++) {
        sorted[a[key]] = o[a[key]];
    }
    return sorted;
}


export default { nComma, getURLParameter, sortObject };